import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import { css, cx } from "emotion"
import Layout from "./../components/layout"
import { ArticlePreview, Post, sizeVariations } from "../components/articlePreview"
import { Grid, Cell, colors, spacing, typography, Breakpoints } from "@avonova-noa/common"
import { IllustrationBlock, IllustrationBlockProps } from "@avonova-noa/components";
import { VideoBlock } from "@avonova-noa/components"
import SEO from "../components/seo"


const h2 = css`
  font-family: Vollkorn;
  font-weight: 300;
  line-height: 1.2em;
  font-size: 2rem;
  text-align: center;
  margin: 2em auto;
`

interface Video {
  title: string
  videoUrl?: string
  videoFile?: {
    file: {
      url: string
    }
  }

}
//filter: { node_locale: { eq: "nb-NO" } }
export default function IndexPage() {

  const pageQuery = useStaticQuery(graphql`
    fragment BlogPost on ContentfulBlogPost{
      id
          title
          type
          slug
          previewSize
          description {
            description
          }
          publishDate
          category {
            name
          }
          heroImage {
            description
            fluid(maxHeight: 854, maxWidth: 1280) {
              aspectRatio
              src
              srcSet
              srcSetWebp
              srcWebp
            }
          }
      }

  

    query  {
      contentfulFrontPage {
        id
        pageTitle
        featuredArticle {
          ...BlogPost
        } 
      featuredVideo {
          title
            videoUrl
            videoFile {
            file {
              url
            }
            } 
         }
         
        recentlyPublished {
          ...BlogPost
        }
        articlePreviews {
          ...BlogPost
        }
        blocksArea { 
          ... on ContentfulIllustrationBlock {
            description {
              description
            }
            header
            destinationLink {
              slug
            }
          }
        }
      }
    }
  `)

  const videoBlockHeader = css`
    text-align: center;
    ${typography.h1}
    margin: 0 auto ${spacing.ms};
    color: ${colors.green.dark2};
    padding: ${spacing.xl} ${spacing.huge};
    @media screen and (${Breakpoints.mobile}) { 
      ${typography.h2}
      padding: ${spacing.ml} 0;
    }
`

  const videoBlockWrapper = css`
      position: relative;
      padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
      @media (${Breakpoints.tablet}) {
        width: calc(100% + 32px);
        margin: 0 -${spacing.s};
        padding-top: 59.25%;

      }

      @media (${Breakpoints.mobile}){
        padding-top: 60.25%;
      }

      .reactPlayer {
        position: absolute;
        top: 0;
        left: 0;
      }
  `;

  const featuredArticle = pageQuery.contentfulFrontPage.featuredArticle as Post
  const featuredVideo = pageQuery.contentfulFrontPage.featuredVideo as Video
  const articlePreviews = pageQuery.contentfulFrontPage.articlePreviews as Post[]
  const recentlyPublished = pageQuery.contentfulFrontPage.recentlyPublished as Post[]
  const IllustrationBlockContent = pageQuery.contentfulFrontPage.blocksArea as IllustrationBlockProps[]

  return (
    <>
      <Layout>
        <SEO title={pageQuery.contentfulFrontPage.pageTitle} url={`https://helsehjulet.com}`} />
        <div className={css`
          background-color:${colors.neutral.light2};
          padding-top: ${spacing.xxl}; 
          @media (${Breakpoints.tablet}) {
            padding-top: 0;
          }
          `}>

          <Grid columns={12} as="ul" tablet={{ columns: 4, gap: "40px" }} gap="80px 40px">
            <Cell width={12} mobile={{ width: 4 }} tablet={{ width: 4 }}>
              {featuredVideo &&
                <>
                  <div className={videoBlockWrapper}>
                    <VideoBlock width="100%" height="100%" url={featuredVideo.videoUrl || featuredVideo.videoFile && featuredVideo.videoFile.file.url}></VideoBlock>
                  </div>
                  {featuredVideo.title &&
                    <h2 className={videoBlockHeader}>{featuredVideo.title}</h2>}

                </>
              }
              {
                featuredArticle &&
                <ArticlePreview post={featuredArticle} size="featured"></ArticlePreview>
              }

            </Cell>
            {recentlyPublished.map(post => (
              <Cell as={"li"} key={post.id} width={4}>
                <ArticlePreview post={post} size={"1/3"}></ArticlePreview>
              </Cell>
            ))
            }
            {articlePreviews &&
              articlePreviews.map(post => (
                <Cell as={"li"} key={post.id} width={
                  post.previewSize === "3/4" ? 8 :
                    post.previewSize === "half" ? 6 :
                      post.previewSize === "1/3" ? 4 :
                        post.type === "Dypdykket" ? 8 :
                          post.type === "Pablo svarer" ? 6 : 4
                }
                  mobile={{ width: 4 }}
                  tablet={{ width: 4 }}
                >
                  <ArticlePreview post={post} size={
                    post.previewSize && post.previewSize !== "default" ? post.previewSize :
                      post.type === "Dypdykket" ? "3/4" :
                        post.type === "Pablo svarer" ? "half" : "1/3"}
                  ></ArticlePreview>
                </Cell>
              ))}
          </Grid>
          {
            IllustrationBlockContent[0] && <IllustrationBlock header={IllustrationBlockContent[0].header} destinationLink={IllustrationBlockContent[0].destinationLink} description={IllustrationBlockContent[0].description} />
          }
        </div>
      </Layout >
    </>
  )
}
